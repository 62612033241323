// Palette
export const PRIMARY = "#263590";
export const PRIMARY_MAIN = "#5E68ED";
export const SECONDARY_MAIN = "#89DAFF";
export const DANGER = "#E25241";

// Contrast
export const WHITE = "#FFFFFF";
export const BLACK = "#000000";

// Priority
export const PRIO1 = "#FAA";
export const PRIO2 = "#CCC";
export const PRIO3 = "#ADA";

// Custom
export const FOCUS_BOX_SHADOW = "#98a2de";
export const COLUMN_COLOR = "#F1F1F6";
export const TASK_G = "#646e81";
export const OWNER_COLOR = "#ffbe47";

// Reds
export const R50 = "#FFEBE6";
export const R75 = "#FFBDAD";
export const R100 = "#FF8F73";
export const R200 = "#FF7452";
export const R300 = "#FF5630";
export const R400 = "#DE350B";
export const R500 = "#BF2600";

// Yellows
export const Y50 = "#FFFAE6";
export const Y75 = "#FFF0B3";
export const Y100 = "#FFE380";
export const Y200 = "#FFC400";
export const Y300 = "#FFAB00";
export const Y400 = "#FF991F";
export const Y500 = "#FF8B00";

// Greens
export const G50 = "#E3FCEF";
export const G75 = "#ABF5D1";
export const G100 = "#79F2C0";
export const G200 = "#57D9A3";
export const G300 = "#36B37E";
export const G400 = "#00875A";
export const G500 = "#006644";

// Blues
export const B50 = "#DEEBFF";
export const B75 = "#B3D4FF";
export const B100 = "#4C9AFF";
export const B200 = "#2684FF";
export const B300 = "#0065FF";
export const B400 = "#0052CC";
export const B500 = "#0747A6";

// Purples
export const P50 = "#EAE6FF";
export const P75 = "#C0B6F2";
export const P100 = "#998DD9";
export const P200 = "#8777D9";
export const P300 = "#6554C0";
export const P400 = "#5243AA";
export const P500 = "#403294";

// Teals
export const T50 = "#E6FCFF";
export const T75 = "#B3F5FF";
export const T100 = "#79E2F2";
export const T200 = "#00C7E6";
export const T300 = "#00B8D9";
export const T400 = "#00A3BF";
export const T500 = "#008DA6";

// Neutrals
export const N0 = "#FFFFFF";
export const N10 = "#FAFBFC";
export const N20 = "#F4F5F7";
export const N30 = "#EBECF0";
export const N40 = "#DFE1E6";
export const N50 = "#C1C7D0";
export const N60 = "#B3BAC5";
export const N70 = "#A5ADBA";
export const N80 = "#97A0AF";
export const N90 = "#8993A4";
export const N100 = "#7A869A";
export const N200 = "#6B778C";
export const N300 = "#5E6C84";
export const N400 = "#505F79";
export const N500 = "#42526E";
export const N600 = "#344563";
export const N700 = "#253858";
export const N800 = "#172B4D";

// ATTENTION: update the tints if you update this
export const N900 = "#091E42";

// Each tint is made of N900 and an alpha channel
export const N10A = "rgba(9, 30, 66, 0.02)";
export const N20A = "rgba(9, 30, 66, 0.04)";
export const N30A = "rgba(9, 30, 66, 0.08)";
export const N40A = "rgba(9, 30, 66, 0.13)";
export const N50A = "rgba(9, 30, 66, 0.25)";
export const N60A = "rgba(9, 30, 66, 0.31)";
export const N70A = "rgba(9, 30, 66, 0.36)";
export const N80A = "rgba(9, 30, 66, 0.42)";
export const N90A = "rgba(9, 30, 66, 0.48)";
export const N100A = "rgba(9, 30, 66, 0.54)";
export const N200A = "rgba(9, 30, 66, 0.60)";
export const N300A = "rgba(9, 30, 66, 0.66)";
export const N400A = "rgba(9, 30, 66, 0.71)";
export const N500A = "rgba(9, 30, 66, 0.77)";
export const N600A = "rgba(9, 30, 66, 0.82)";
export const N700A = "rgba(9, 30, 66, 0.89)";
export const N800A = "rgba(9, 30, 66, 0.95)";

// Dark Mode Neutrals
export const DN900 = "#E6EDFA";
export const DN800 = "#DCE5F5";
export const DN700 = "#CED9EB";
export const DN600 = "#B8C7E0";
export const DN500 = "#ABBBD6";
export const DN400 = "#9FB0CC";
export const DN300 = "#8C9CB8";
export const DN200 = "#7988A3";
export const DN100 = "#67758F";
export const DN90 = "#56637A";
export const DN80 = "#455166";
export const DN70 = "#3B475C";
export const DN60 = "#313D52";
export const DN50 = "#283447";
export const DN40 = "#202B3D";
export const DN30 = "#1B2638";
export const DN20 = "#121A29";
export const DN10 = "#0E1624";

// Each dark tint is made of DN0 and an alpha channel
export const DN800A = "rgba(13, 20, 36, 0.06)";
export const DN700A = "rgba(13, 20, 36, 0.14)";
export const DN600A = "rgba(13, 20, 36, 0.18)";
export const DN500A = "rgba(13, 20, 36, 0.29)";
export const DN400A = "rgba(13, 20, 36, 0.36)";
export const DN300A = "rgba(13, 20, 36, 0.40)";
export const DN200A = "rgba(13, 20, 36, 0.47)";
export const DN100A = "rgba(13, 20, 36, 0.53)";
export const DN90A = "rgba(13, 20, 36, 0.63)";
export const DN80A = "rgba(13, 20, 36, 0.73)";
export const DN70A = "rgba(13, 20, 36, 0.78)";
export const DN60A = "rgba(13, 20, 36, 0.81)";
export const DN50A = "rgba(13, 20, 36, 0.85)";
export const DN40A = "rgba(13, 20, 36, 0.89)";
export const DN30A = "rgba(13, 20, 36, 0.92)";
export const DN20A = "rgba(13, 20, 36, 0.95)";
export const DN10A = "rgba(13, 20, 36, 0.97)";

export const getContrastColor = (hexColor: string) => {
  if (hexColor[0] === "#") {
    hexColor = hexColor.slice(1);
  }

  if (hexColor.length === 3) {
    hexColor = hexColor
      .split("")
      .map((h) => h + h)
      .join("");
  }

  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);

  // Calculate contrast using YIQ ratio
  return (r * 299 + g * 587 + b * 114) / 1000 >= 178 ? BLACK : WHITE;
};

export const getRandomHexColor = () => {
  const newColor = (
    "000000" + Math.floor(0x1000000 * Math.random()).toString(16)
  ).slice(-6);
  return `#${newColor}`;
};
